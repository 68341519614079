import React from 'react';
import MainWrapper, {LogoImageContainer, MainContentSection} from "@deadline/common/ui/five.style";
import {Container} from "../components/Container";
import styled from "styled-components";
import {Link} from "gatsby";
import LogoImage from '@deadline/common/static/images/logoWhite.svg';
import {SEO} from "../components";
import Helmet from "react-helmet";

const TextWrapper = styled.div`
  margin-top: 100px;
  background: #fff;
  padding: 50px;
  border-radius: 5px;
  width: 100%;
  *{
    max-width: initial !important;
    text-align: justify !important;
     font-family: 'Roboto' !important;
    
  }
`;

function ErrorPage() {
    return (
        <MainWrapper>
            <Helmet>
                <title>
                    Simplicity Commerce | Domains List
                </title>
            </Helmet>
            <Container className="mainContainer">
                <MainContentSection>
                    <LogoImageContainer>
                        <Link to={'/'}>
                            <img src={LogoImage} alt="logo" />
                        </Link>
                    </LogoImageContainer>

                    <TextWrapper>
                        <h1>Domains List</h1>
                        <ul>
                            <li>simplicitycommerce.com</li>
                        </ul>
                    </TextWrapper>
                </MainContentSection>
            </Container>
        </MainWrapper>
    );
}

export default ErrorPage;
